const Radio = ({ option, selected, handleClick }) => {
  return (
    <div
      style={{
        backgroundColor: selected ? "#edf8fe" : "#f2f2f2",
        fontSize: "16px",
        padding: "9px 14px",
        borderRadius: "7px",
        color: selected ? "#555555" : "#555555",
        cursor: "pointer",
        // marginRight: "15px",
        // marginBottom: "10px",
        flex: "1 1 auto",

        lineHeight: "18px",
        border: selected ? "2px solid #7babd0" : "2px solid #f2f2f2",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        columnGap: "5px",
      }}
      onClick={() => handleClick(option)}
    >
      {option.icon && (
        <img style={{ width: option.iconSize }} src={option.icon} alt="" />
      )}

      {option.label}
    </div>
  );
};

export default Radio;
