import moment from "moment";
import { getClassName } from "../../../../../Helpers/getClassName";
import { getStationName } from "../../../../../Helpers/getStationName";
import useDirectQueries from "../../../../../Hooks/useDirectQuery";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";

import BookingDetailsContainer from "./BookingDetails.styles";
import langText from "./translations";
const copy =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/copy.png";
const share =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/share.png";

const Content = ({ data }) => {
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;
  const copyPNR = () => {
    navigator.clipboard.writeText(data.pnr);
  };

  const sharePNR = () => {
    const shareData = {
      title: "My PNR Number",
      text: data.pnr,
    };
    window.navigator.share(shareData);
  };

  const { getPNR } = useDirectQueries({ booking: data });

  return (
    <BookingDetailsContainer>
      <div className="train-details">
        {data.trainName} <span>({data.trainNumber})</span>
      </div>

      <div className="journey-details">
        <div className="from">
          <p className="code">{data.source}</p>
          <p className="station">{getStationName(data.source)}</p>
        </div>

        <div className="hr"></div>

        <div className="to">
          <p className="code">{data.destination}</p>
          <p className="station">{getStationName(data.destination)}</p>
        </div>
      </div>
      <div className="time-details">
        <div style={{ textAlign: "left" }}>
          <p className="time">
            {moment(data.departureTime, "h:m").format("h:mm a")}
          </p>
          <p className="date">
            , {moment(data.departureDate).format("DD MMM, YYYY")}
          </p>
        </div>
        {/* <div
          className="class"
          style={{ textAlign: "center", marginTop: "-0.625rem" }}
        >
          <p>3AC</p>
          <span>AC 3rd Class</span>
        </div> */}
        <div style={{ textAlign: "right" }}>
          <p className="time">
            {moment(data.arrivalTime, "h:m").format("h:mm a")}
          </p>
          <p className="date">
            ,{moment(data.arrivalDate).format("DD MMM, YYYY")}
          </p>
        </div>
      </div>

      <div className="seperator"></div>
      <div className="pnr">
        <p>
          PNR: <span>{data.pnr}</span>
          {window.navigator && window.navigator.clipboard && (
            <img
              src={copy}
              style={{
                width: "16px",
                marginLeft: "20px",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={copyPNR}
              alt=""
            />
          )}
          {window.navigator && window.navigator.canShare && (
            <img
              style={{ width: "16px", cursor: "pointer" }}
              src={share}
              alt=""
              onClick={sharePNR}
            />
          )}
        </p>
        {data.bookingStatus === 1 && (
          <button onClick={getPNR}>
            <img
              src="https://d3upbvvdvllr10.cloudfront.net/dishav3/pnr.svg"
              alt=""
              style={{ width: "25px", marginRight: "5px" }}
            />
            {langText[lang].pnr}
          </button>
        )}
      </div>

      <div className="seperator"></div>
      <div className="other-details">
        <div>
          <p>{langText[lang].class}</p>
          <p>{langText[lang].quota}</p>
          <p>{langText[lang].date}</p>
          <p>{langText[lang].amount}</p>
          {data.orderId && <p>{langText[lang].order}</p>}
        </div>

        <div>
          <span>
            {data.class} - {getClassName(data.class)}
          </span>
          <span>{data.quota}</span>
          <span>
            {moment(data.bookingDate).format("DD MMM, YYYY | hh:mm a")}
          </span>
          <span>{data.amount}</span>
          {data.orderId && <span> {data.orderId}</span>}
        </div>
      </div>
      <div className="seperator"></div>

      <div className="passengers">
        <h3>
          {langText[lang].passengers} ({data.passengers.length})
        </h3>
        {data.passengers.map((passenger) => (
          <div className="passenger">
            <div className="header">
              <p className="name">{passenger.name}</p>
              <span className="age-gender">
                <span>{passenger.gender}</span> <span>|</span>{" "}
                <span>{passenger.age}</span>
              </span>
            </div>

            <div className="details">
              <div className="status">
                <p
                  className="value"
                  style={{ color: passenger.atCurrent.status.color }}
                >
                  {passenger.atCurrent.status.label}
                </p>
              </div>
              <div className="seat-details">
                <div>
                  <p>{langText[lang].coach}</p>
                  <span>{passenger.atCurrent.coach}</span>
                </div>
                <div>
                  <p>{langText[lang].seat}</p>
                  <span>{passenger.atCurrent.seat}</span>
                </div>
                <div>
                  <p>{langText[lang].berth}</p>
                  <span>{passenger.atCurrent.berth}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </BookingDetailsContainer>
  );
};

export default Content;
