/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/first */

import { useEffect, useRef, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import { IMAGE_MIC_OFF, IMAGE_MIC_ON } from "../../Assets";
import useGetReduxState from "../../Hooks/useGetReduxState";
import Spinner from "../../UI/UIComponents/Spinner";
import { stopAudio as stopMessageVoice } from "../../Store/Dispatcher/behaviour";
import ReactDOM from "react-dom";
import Container from "./styles";
import langText from "./translations";
import { setMicClose } from "../../Helpers/misc";

const close =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/close.png";
const disha = require("../../Assets/Local/disha-message-avatar.ico");

interface mic {
  useEnglish: boolean;
  callback: any;
  onClose: any;
  content: any;
  isUPI?: boolean;
  style?: any;
  onChange?: any;
  audio?: string;
  skipMessage?: string;
}

export const UPIMicrophone: React.FC<mic> = (props: any) => {
  const { transcript, listening } = useSpeechRecognition();

  const [micOn, setMicOn] = useState(false);
  const [gotFinalSpeech, setGotFinalSpeech] = useState(false);
  const queryLang = useGetReduxState().behaviour.queryLang;

  const getLanguage = () => {
    switch (queryLang) {
      case "hi":
        return "hi-IN";
      case "en":
        return "en-IN";
      case "us":
        return "en-US";
      case "gb":
        return "en-UK";

      case "gu":
        return "gu-IN";

      default:
        return "en-IN";
    }
  };

  let lang = "en-IN";
  if (props.useEnglish) lang = "en-IN";
  else lang = getLanguage();

  const uiLang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;

  const microphone = useRef(false);

  const isMobile = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      )
    ) {
      // true for mobile device
      return true;
    } else {
      // false for not mobile device
      return false;
    }
  };

  const startRecognition = () => {
    setIsLoaded(true);
    setMicOn(true);
    if (!isMobile()) {
      let startAudio = new Audio(
        "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/start.mp3"
      );
      startAudio.play();
    }

    // startAudioRecording();
    SpeechRecognition.startListening({
      continuous: true,
      language: lang,
    });
  };

  const stopRecognition = () => {
    if (!isMobile()) {
      let audio = new Audio(
        "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/caught.mp3"
      );
      audio.play();
    }

    SpeechRecognition.stopListening();
    setGotFinalSpeech(true);
    setToggle(false);
    setTimeout(() => {
      if (!microphone.current) {
        props.callback(transcript);
        microphone.current = true;
        // stopAudioRecording();
      }
    }, 1000);
  };

  const [isloaded, setIsLoaded] = useState(false);

  const closeMic = () => {
    stopRecognition();
  };

  useEffect(() => {
    let closed = false;
    const speaker = new Audio(props.audio);
    stopMessageVoice();
    if (!listening && !isloaded) {
      if (props.audio) {
        speaker.play();
        speaker.onended = () => {
          !closed && startRecognition();
        };
      } else {
        !closed && startRecognition();
      }
    }
    setMicClose(closeMic);

    return () => {
      closed = true;
      speaker.pause();
    };
  }, []);

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (transcript) {
      setToggle((prev) => !prev);
    }

    props.onChange && props.onChange(transcript);

    const delayDebounceFn = setTimeout(() => {
      if (transcript) stopRecognition();
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [transcript]);

  return ReactDOM.createPortal(
    <Container
      style={{
        height: "380px",
        ...props.style,
        position: "absolute",
        bottom: "24px",
        paddingBottom: "8px",
      }}
    >
      <img
        src={close}
        onClick={() => {
          SpeechRecognition.stopListening();
          props.onClose();
        }}
        style={{
          width: "15px",
          position: "absolute",
          right: "15px",
          top: "15px",
          cursor: "pointer",
          zIndex: 999,
        }}
        alt="Close Microphone Button"
        tabIndex={1}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            SpeechRecognition.stopListening();
            props.onClose();
          }
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div
          style={{
            padding: "20px 22px",
            height: "50%",
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={disha} alt="" style={{ width: "60px" }} />

          {props.isUPI && (
            <p
              style={{
                margin: 0,
                color: "#606060",
                textAlign: "center",
                fontSize: "16px",
                marginTop: "16px",
                width: "50%",
              }}
            >
              {langText[uiLang].payingUPI}
            </p>
          )}
          {transcript === "" ? (
            <p
              style={{
                width: "100%",
                fontSize: "20px",
                textAlign: "center",
                color: "#131313",
                margin: 0,
                marginTop: "25px",
              }}
            >
              {props.isUPI
                ? langText[uiLang].provideId
                : langText[uiLang].likeToPay}
            </p>
          ) : (
            <p
              style={{
                width: "100%",
                fontSize: "20px",
                textAlign: "center",
                color: "#131313",
                margin: 0,
                marginTop: "25px",
              }}
            >
              {transcript}
            </p>
          )}
        </div>
        <div
          style={{
            height: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            width: "100%",
          }}
        >
          {micOn && transcript === "" && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                left: "50%",
                top: "-25px",
                transform: "translateX(-50%)",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: "#606060",
                  textAlign: "center",
                  fontSize: "16px",
                  marginTop: "16px",
                  width: "50%",
                }}
              >
                {props.isUPI
                  ? langText[uiLang].suggestions.upiId
                  : langText[uiLang].suggestions.upi}
              </p>
            </div>
          )}

          {!gotFinalSpeech ? (
            <div className={toggle ? "outer-pulse-active" : "outer-pulse"}>
              <div
                className={
                  transcript.length > 1 && micOn
                    ? "microphone-new-inactive"
                    : transcript.length === 0 && !micOn
                    ? "microphone-new-inactive"
                    : "microphone-new-active"
                }
              >
                <img src={micOn ? IMAGE_MIC_ON : IMAGE_MIC_OFF} alt="" />
              </div>
            </div>
          ) : (
            <div className="microphone-new-inactive">
              <Spinner
                borderWidth={"3px"}
                activeColor={"#f9f9f9"}
                inactiveColor={"#346db3"}
                speed={"0.8s"}
                size={"30px"}
              />
            </div>
          )}
        </div>
        {props.skipMessage && (
          <div
            style={{
              marginBottom: "16px",
              color: "#326abb",
              cursor: "pointer",
            }}
            onClick={() => {
              SpeechRecognition.stopListening();
              props.onClose();
            }}
          >
            {props.skipMessage}
          </div>
        )}
      </div>
    </Container>,
    document.getElementById("corover-body")
  );
};
