import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Microphone } from "../../../Chatbot/Microphone";
import styles from "./TextBox.module.css";
export const TextBox = (props: any) => {
  const {
    label,
    placeholder,
    id,
    onChange,
    error,
    value,
    type,
    maxLength,
    handleVoice,
    content,
    onClick,
    suggestion,
    onSelect,
  } = props;
  const [clicked, setClicked] = useState(false);
  const [mic, setMic] = useState(false);

  const handleChange = (e) => {
    if (e.target.value !== "") setClicked(true);
    else setClicked(false);

    onChange(e);
  };

  return (
    <div style={{ width: "100%" }} onClick={onClick}>
      <div
        style={{ marginTop: clicked ? "16px" : "10px" }}
        className={styles.textbox2}
      >
        {value !== "" && (
          <label style={{}} htmlFor="name">
            {label}
          </label>
        )}

        <input
          id={id}
          name={id}
          type={type}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
        />
        {suggestion && suggestion.length > 0 && (
          <div
            style={{
              position: "absolute",
              border: "1px solid #d4d4d4",
              borderTop: "none",
              zIndex: "99",
              top: "100%",
              left: "0",
              right: "0",
              background: "white",
              borderBottom: "1px solid #d4d4d4",
              borderRadius: "4px",
              maxHeight: "160px",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {suggestion.map((elem, index) => (
              <p
                key={index}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                  borderBottom: "1px solid #d4d4d4",
                }}
                onClick={() => onSelect(elem)}
              >
                {elem}
              </p>
            ))}
          </div>
        )}
        <FontAwesomeIcon
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            color: "#a5a4a4",
            fontSize: "20px",
            cursor: "pointer",
          }}
          icon={faMicrophone}
          onClick={() => setMic(true)}
        />
      </div>
      {error && (
        <p
          style={{
            fontSize: "12px",
            color: "tomato",
            fontWeight: "400",
            marginTop: "5px",
            marginLeft: "4px",
          }}
        >
          {error}
        </p>
      )}
      {mic && (
        <Microphone
          useEnglish={true}
          callback={(value) => {
            if (value) handleVoice(value, id);
            setMic(false);
          }}
          onClose={() => setMic(false)}
          content={content}
        />
      )}
    </div>
  );
};
