import { useState } from "react";
import Radio from "./Radio";

export const RadioSelect = ({
  options,
  label,
  bordered,
  handleSelect,
  value,
  error,
}) => {
  const [selected, setSelected] = useState(value);
  const handleChange = (option) => {
    setSelected(option.value);
    handleSelect(option.value);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
        rowGap: "10px",
        position: "relative",
        padding: "10px",
        border: bordered ? "1px solid #d8d8d8" : "none",
        borderRadius: "6px",
        marginTop: "20px",
        marginBottom: "20px",
        columnGap: "10px",
        paddingTop: bordered ? "15px" : "0px",
      }}
    >
      {label && (
        <span
          style={{
            position: "absolute",
            top: "-9px",
            left: "10px",
            fontSize: "12px",
            backgroundColor: "white",
            padding: "0px 4px",
            color: "#323232",
          }}
        >
          {label}
        </span>
      )}

      {options
        .filter((option) => option.show)
        .map((option) => (
          <Radio
            handleClick={handleChange}
            option={option}
            selected={option.value === selected}
          />
        ))}
      {error && (
        <p
          style={{
            width: "100%",
            fontSize: "12px",
            color: "tomato",
            fontWeight: "400",
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};
