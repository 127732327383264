/* eslint-disable react-hooks/exhaustive-deps */
import ReactDOM from "react-dom";
import Container from "../../../Chatbot/Microphone/styles";
import { TextBox } from "../../../UI/UIComponents/TextBox/TextBox";
import { useEffect, useState } from "react";
import {
  getPaymentStatus,
  upiTrigger,
  validateVPA,
} from "../../../Api/upi.paytm";
import translations from "./translations";
import { closeMic, updateUPIId, upiHandlers } from "../../../Helpers/misc";
import { UPIMicrophone } from "../../../Chatbot/Microphone/UPIMic";
import Spinner from "../../../UI/UIComponents/Spinner";
const disha = require("../../../Assets/Local/disha-message-avatar.ico");

const close =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/close.png";

export const UPIPopup = ({
  lang,
  upiId,
  txnToken,
  mid,
  orderId,
  handlePaymentDone,
  onClose,
  setUpiId,
  setHideBack,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isProceeding, setIsProceeding] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [error, setError] = useState("");
  const [suggestion, setSuggestion] = useState([]);
  const [mic, setMic] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [vpa, setVPA] = useState("");

  const callUPI = async () => {
    if (isDisabled || !upiId) return;
    setIsDisabled(true);
    const validation = await validateVPA(txnToken, mid, orderId, upiId);

    if (!validation.isValid) {
      setError(translations[lang].selectMode.error);
      setIsDisabled(false);
      return;
    }

    const response = await upiTrigger(txnToken, mid, orderId, validation.vpa);

    if (response.error || response.body.resultInfo.resultStatus === "F") {
      setError(
        lang === "en"
          ? "Error encountered, please retry..."
          : lang === "hi"
          ? "त्रुटि आई, कृपया पुनः प्रयास करें..."
          : "ભૂલ આવી, કૃપા કરીને ફરી પ્રયાસ કરો..."
      );
      setIsDisabled(false);
      return;
    }
    setVPA(validation.vpa);
    setError("");
    setIsProceeding(true);
    setHideBack(true);
  };

  useEffect(() => {
    setIsCorrect(!!upiId);

    const interval = setInterval(() => {
      getPaymentStatus(orderId).then((isComplete) => {
        if (isComplete) {
          clearInterval(interval);
          setTimeout(() => {
            handlePaymentDone();
          }, 5000);
        }
      });
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const onSelect = (value) => {
    setUpiId(value);
    setSuggestion([]);
  };

  const updateSuggestion = (value) => {
    if (!value.trim()) setSuggestion([]);
    else if (value.includes("@")) {
      const val = value.trim().split("@")[0];
      const handle = value.trim().split("@")[1];
      setSuggestion(
        upiHandlers
          .filter((elem) => elem.startsWith(handle))
          .map((elem) => `${val}@${elem}`)
      );
    } else {
      setSuggestion(upiHandlers.map((elem) => `${value}@${elem}`));
    }
  };

  return ReactDOM.createPortal(
    <Container
      style={{
        height: "340px",
        background: "white",
        bottom: "24px",
        position: "absolute",
        paddingBottom: "20px",
      }}
      onClick={(e) =>
        e.target.tagName.toLowerCase() !== "input" && setSuggestion([])
      }
    >
      {!isProceeding && (
        <img
          src={close}
          onClick={() => onClose()}
          style={{
            width: "15px",
            position: "absolute",
            right: "15px",
            top: "15px",
            cursor: "pointer",
            zIndex: 999,
          }}
          alt="Close Microphone Button"
          tabIndex={1}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "20px 22px",
            height: "50%",
            width: "90%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={disha} alt="" style={{ width: "60px" }} />
          <p
            style={{
              margin: 0,
              color: "#606060",
              textAlign: "center",
              fontSize: "16px",
              marginTop: "16px",
              width: "100%",
            }}
          >
            {isProceeding
              ? translations[lang].selectMode.approvePayment
              : translations[lang].selectMode.confirmUPI}
          </p>
          {upiId && !isProceeding && isCorrect && (
            <p
              style={{
                width: "100%",
                fontSize: "20px",
                textAlign: "center",
                color: "#131313",
                margin: 0,
                marginTop: "25px",
              }}
            >
              {translations[lang].selectMode.isCorrect}
            </p>
          )}
          {isProceeding && (
            <p
              style={{
                width: "100%",
                fontSize: "20px",
                textAlign: "center",
                color: "#131313",
                margin: 0,
                marginTop: "25px",
              }}
            >
              {translations[lang].selectMode.openUPI}
              <br />
              <span style={{ fontSize: "14px" }}>
                {translations[lang].selectMode.requestSent.replace("#", vpa)}
              </span>
            </p>
          )}
          {!isProceeding && (
            <TextBox
              error={error}
              value={upiId}
              onChange={(e) => {
                setUpiId(e.target.value);
                setIsCorrect(false);
                setError("");
                updateSuggestion(e.target.value);
              }}
              placeholder={translations[lang].selectMode.placeHolder}
              suggestion={suggestion}
              onSelect={onSelect}
              animate={animate}
              handleMic={() => {
                if (!mic) {
                  setAnimate(true);
                  setMic(true);
                } else {
                  setAnimate(false);
                  setMic(false);
                  closeMic && closeMic();
                }
              }}
              handleVoice={(value) => {
                setUpiId(updateUPIId(value));
              }}
              // hideMic
            />
          )}
          {upiId && !isProceeding && !error && isCorrect && (
            <p
              style={{
                margin: 0,
                color: "#606060",
                textAlign: "center",
                fontSize: "12px",
                marginTop: "8px",
                width: "100%",
                fontStyle: "italic",
              }}
            >
              {translations[lang].selectMode.edit}
            </p>
          )}
        </div>
        {!isProceeding ? (
          <button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              width: "80%",
              gap: "10px",
              backgroundColor: `${isDisabled ? "#738ba9" : "#346db3"}`,
              color: "white",
              fontSize: "18px",
              fontWeight: 500,
              padding: "12px",
              borderRadius: "8px",
              marginTop: "24px",
              cursor: "pointer",
              border: "none",
            }}
            disabled={isDisabled}
            onClick={() => {
              callUPI();
            }}
          >
            {lang === "en"
              ? "Proceed to Pay"
              : lang === "hi"
              ? "पेमेंट शुरू करें"
              : "ચુકવણી શરૂ કરો"}
            <img src="forward-angle.svg" style={{ width: "10px" }} alt="" />
          </button>
        ) : (
          <div
            style={{
              display: "flex",
              margin: "16px",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Spinner
              borderWidth={"3px"}
              activeColor={"#f9f9f9"}
              inactiveColor={"#346db3"}
              speed={"0.8s"}
              size={"30px"}
            />
            <span style={{ textAlign: "center", lineHeight: "20px" }}>
              {translations[lang].selectMode.dontBack.split("#")[0]}
              <br />
              {translations[lang].selectMode.dontBack.split("#")[1]}
            </span>
          </div>
        )}
      </div>
      {mic && (
        <UPIMicrophone
          useEnglish={true}
          callback={(value) => {
            value && setUpiId(updateUPIId(value));
            setMic(false);
            setAnimate(false);
          }}
          onClose={() => {
            setMic(false);
            setAnimate(false);
          }}
          onChange={(value) => {
            value && setUpiId(updateUPIId(value));
          }}
          content=""
          isUPI={true}
          style={{
            display: "none",
          }}
        />
      )}
    </Container>,
    document.getElementById("corover-body")
  );
};
