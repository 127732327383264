import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllStations, getPopularStations } from "../Api/stations";
import {
  addCounter,
  getCountries,
  getPendingBookings,
  getSettings,
  getUserData,
} from "../Api/utils";
import { setFaqsForLang } from "../Store/Actions/faqs";
import {
  setAppLang,
  setCxToken,
  setDisabledSettings,
  setFullScreen,
  setQueryLang,
  setSessionId,
} from "../Store/Dispatcher/behaviour";
import {
  setJourneyDate,
  setJourneyDestination,
  setJourneyQuota,
  setJourneySource,
} from "../Store/Dispatcher/journey";
import { setPassengers } from "../Store/Dispatcher/passengerForm";
import { setUser } from "../Store/Dispatcher/user";
import { isAdult, isChild } from "../Templates/PassengerForm/utils";
import useGetReduxState from "./useGetReduxState";
import usePersistentStorage from "./usePersistentStorage";
import { v4 as uuid } from "uuid";
import {
  setAllStations,
  setCountires,
  setPopularStations,
} from "../Store/Dispatcher/utils";
import Login from "../Templates/Login2";
import { setLocalData } from "../Store/Dispatcher/behaviour";

const useFirstLoadSetup = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { journeys, cxtoken, saveUserToken } = usePersistentStorage();

  const [showPending, setShowPending] = useState(null);

  useEffect(() => {
    let isSearchFromIRCTCForm = false;
    if (window.location.search) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString.toLowerCase());
      const source = urlParams.get("from");
      const destination = urlParams.get("to");

      isSearchFromIRCTCForm = Boolean(source) && Boolean(destination);
    }

    if (journeys.length > 0 && !isSearchFromIRCTCForm) {
      let obj = journeys[0];
      setJourneySource(obj.source);
      setJourneyDestination(obj.destination);
    }
  }, [journeys]);

  useEffect(() => {
    setCxToken(cxtoken);
    if (cxtoken) {
      getUser();
      checkIfPending();
    }
  }, [cxtoken]);

  const getLocalLang = () => {
    let zone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase();

    if (zone.includes("america")) return "us";
    if (zone.includes("london")) return "gb";
    return "en";
  };
  const irctcConfig = useGetReduxState().passengerform.irctcConfig;

  const getInititialBerth = (age) => {
    const childBerthMandatory = irctcConfig.childBerthMandatory;

    if (isAdult(age)) return "NBC";

    if (isChild(age)) {
      return childBerthMandatory ? "" : "";
    }

    return "";
  };

  const constructPassenger = (passenger) => {
    return {
      name: passenger.passengerName || passenger.name || "",
      age: passenger.passengerAge.toString() || passenger.age.toString() || "",
      gender: passenger.passengerGender || passenger.gender || "",
      food: irctcConfig.applicableFoodChoices.includes(
        passenger.passengerFoodChoice
      )
        ? passenger.passengerFoodChoice
        : "",
      nationality: passenger.passengerNationality || "",
      passport: passenger.passengerCardNumber || "",
      dob: passenger.psgnConcDOB || "",
      berth: getInititialBerth(passenger.passengerAge.toString()),
      isSelected: false,
    };
  };

  const refactorForMatch = (passenger) => {
    return JSON.stringify(passenger);
  };

  const getUser = async () => {
    let data = await getUserData();
    if (data.length == 0) {
      data = await getUserData();
    }
    setUser(data);

    let actualData = data[0];

    let savedP = actualData.masterData ? JSON.parse(actualData.masterData) : [];

    const savedPassengers = savedP.map((passenger) =>
      constructPassenger(passenger)
    );

    let total = [...savedPassengers].map((passenger) =>
      refactorForMatch(passenger)
    );

    let refactored = [...new Set(total)];
    // for (let p of total) {
    //   if (!refactored.includes(p)) refactored.push(p);
    // }
    setPassengers(refactored.map((passenger) => JSON.parse(passenger)));
  };

  const getDisabledSettings = async () => {
    const { isDisabled, booking } = await getSettings();

    setDisabledSettings({ other: isDisabled, booking: booking });
  };

  const checkIfPending = async () => {
    const { isPending, data } = await getPendingBookings();
    if (isPending) setShowPending(data);
    // setBookingIsPending(isPending)
  };

  const setAllData = async () => {
    const stations = await getAllStations();
    setAllStations(stations);

    const popular = await getPopularStations();
    setPopularStations(popular);

    const countries = await getCountries();
    setCountires(countries);
  };

  const setSession = async () => {
    setSessionId(uuid());
  };

  useEffect(() => {
    setAllData();
    setSession();
    setAppLang("en");
    setQueryLang(getLocalLang());

    getDisabledSettings();
    dispatch(setFaqsForLang("en"));

    setJourneyDate(moment().format("YYYYMMDD"));
    setJourneyQuota("GN");
  }, []);

  useEffect(() => {
    window.parent.postMessage("LOADED", "*");
  }, []);
  // const localData = useGetReduxState().app.local;

  const isInIframe = () => window.location !== window.parent.location;

  useEffect(() => {
    if (isInIframe()) {
      window.parent.postMessage("GET_DISHA_DATA", "*");
      window.onmessage = (e) => {
        if (e.data?.action === "GET_DISHA_DATA") {
          setLocalData(e.data.data);
          let data = JSON.parse(e.data.data);
          setTimeout(() => {
            if (!data?.usertoken) {
              let uu = uuid();
              saveUserToken(uu, data);
            } else saveUserToken(data.usertoken, data);

            if (data?.mobile) {
              setFullScreen({
                type: "LOGIN",
                component: (
                  <Login
                    handleLogin={() => {
                      setFullScreen({ type: null, component: null });
                    }}
                    handleCloseDrawer={() =>
                      setFullScreen({ type: null, component: null })
                    }
                  />
                ),
              });
            }
          }, 500);
        }
      };
    } else {
      setLocalData(localStorage.getItem("disha-data"));
      let data = JSON.parse(localStorage.getItem("disha-data"));
      setTimeout(() => {
        if (!data?.usertoken) {
          let uu = uuid();
          saveUserToken(uu, data);
        } else saveUserToken(data.usertoken, data);

        if (data?.mobile) {
          setFullScreen({
            type: "LOGIN",
            component: (
              <Login
                handleLogin={() => {
                  setFullScreen({ type: null, component: null });
                }}
                handleCloseDrawer={() =>
                  setFullScreen({ type: null, component: null })
                }
              />
            ),
          });
        }
      }, 500);
      // getDataFromLocalStorage(JSON.parse(localStorage.getItem("disha-data")));
    }
  }, []);

  return { isLoaded: true, showPending, setShowPending };
};

export default useFirstLoadSetup;
