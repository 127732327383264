import { useEffect } from "react";
import ReactDOM from "react-dom";
import useGetReduxState from "../../Hooks/useGetReduxState";
import Speaker from "../../UI/UIComponents/Speaker";
import audios from "../../UI/UIComponents/Speaker/audios";
import { Header } from "./Header/Header";
import OTP from "./OTP";
import PaymentSuccess from "./PaymentSuccess";
import { PayTm } from "./PayTM/PayTm";
import langText from "./translations";
const BookingSteps = ({
  data,
  goBack,
  currentStep,
  handlePaymentDone,

  resendOTP,
  bookTicket,
  bookTicketPassword,
  loading,
}) => {
  // const [currentStep, setCurrentStep] = useState(1);
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;
  // const [error, setError] = useState("");
  // const [message, setMessage] = useState("");

  // const [message, setMessage] = useState(
  //   "Otp has been successfully sent on registered number *******974 and email abh*************@gmail.com"
  // );

  // const mock = () => {
  //   setTimeout(() => {
  //     setCurrentStep(2);
  //     setTimeout(() => {
  //       setCurrentStep(3);
  //     }, 5000);
  //   }, 12000);
  // };

  useEffect(() => {
    // mock();
  }, []);

  const getAudio = () => {
    if (currentStep === 1) return audios[lang].PROCEED_PAYMENT;
    if (currentStep === 2) return audios[lang].PAYMENT_SUCCESS;
    if (currentStep === 3) return audios[lang].BOOKING_OTP;
  };

  return ReactDOM.createPortal(
    <div
      style={{
        background: "white",
        width: "100%",
        inset: 0,
        position: "absolute",
        zIndex: 5,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          margin: "0px auto",
          borderBottom: "1px solid gainsboro",
          paddingBottom: "10px",
        }}
      >
        {" "}
        <div>
          <p
            style={{
              margin: "0",
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "700",
              color: "#3a3a3a",
              marginTop: "10px",
            }}
          >
            {langText[lang].header}
          </p>
          <span
            style={{
              display: "block",
              textAlign: "center",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "4px",
              color: "#696969",
              marginBottom: "12px",
            }}
          >
            {/* {langText[lang].text1} */}
          </span>
        </div>
        {/* <Header currentStep={currentStep} /> */}
        <div style={{ position: "absolute", top: "14px", right: "14px" }}>
          <Speaker
            sizeBg={"25px"}
            sizeIcon={"12px"}
            audioUrl={getAudio()}
            disabled={false}
          />
        </div>
      </div>

      <div
        style={{
          maxHeight: "calc(100% - 110px)",
          position: "relative",
          height: "100%",
        }}
        id="BookingStepsDiv"
      >
        {currentStep === 1 && (
          <PayTm
            clientTransactionId={data.clientTransactionId}
            paymentAmount={data.paymentAmount}
            txnToken={data.txnToken}
            handlePaymentDone={handlePaymentDone}
            goBack={goBack}
          />
          // <Payment />
        )}

        {currentStep === 2 && (
          <PaymentSuccess
            txnId={data.clientTransactionId}
            amount={data.paymentAmount}
          />
        )}

        {/* {currentStep === 3 && (
          <OTP
            resendOtp={resendOTP}
            bookTicket={bookTicket}
            loading={loading}
            userId={data.userId}
            txnId={data.clientTransactionId}
            bookTicketPassword={bookTicketPassword}
          />
        )} */}
      </div>
    </div>,
    document.getElementById("corover-body")
  );
};

export default BookingSteps;
