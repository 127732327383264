import axios from "axios";
import { isProdEnv } from "../Helpers/getEnvironment";

const baseURL = isProdEnv
  ? `https://securegw.paytm.in`
  : `https://securegw-stage.paytm.in`;

export const validateVPA = async (txnToken, mid, orderId, vpa) => {
  const payload = {
    body: {
      vpa,
      mid,
    },
    head: {
      tokenType: "TXN_TOKEN",
      txnToken,
    },
  };

  return await axios
    .post(
      `${baseURL}/theia/api/v1/vpa/validate?mid=${mid}&orderId=${orderId}`,
      payload
    )
    .then((response) => ({
      isValid: response.data.body.valid,
      vpa: response.data.body.vpa,
    }))
    .catch((_) => ({ isValid: false, vpa: null }));
};

export const upiTrigger = async (txnToken, mid, orderId, vpa) => {
  const payload = {
    head: {
      txnToken,
    },
    body: {
      requestType: "NATIVE",
      mid,
      orderId,
      paymentMode: "UPI",
      payerAccount: vpa,
    },
  };

  return await axios
    .post(
      `${baseURL}/theia/api/v1/processTransaction?mid=${mid}&orderId=${orderId}`,
      payload
    )
    .then((response) => response.data)
    .catch((_) => ({
      error: true,
    }));
};

export const getPaymentStatus = async (orderId) => {
  return await axios
    .get(`/dishaAPI/bot/upiStatus/${orderId}`)
    .then((response) => response.data)
    .catch(() => false);
};
