import { useEffect, useState } from "react";
import langText from "./translations";
import { parseBooking } from "./utils";
import "./styles.scss";
import moment from "moment";
import { getClassName } from "../../Helpers/getClassName";
import { getQuotaName } from "../../Helpers/getQuotaName";
import { getStationName } from "../../Helpers/getStationName";

import Spinner from "../../UI/UIComponents/Spinner";
const disha = require("../../Assets/Local/disha-pass.png");
const irctc = require("../../Assets/Local/irctc.png");
const railway = require("../../Assets/Local/indian-railway.png");
const arrow = require("../../Assets/Local/arrow_right.svg").default as string;
const corover = require("../../Assets/Local/corover.png");

const download =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/download.png";

const Eticket = ({ isForCard, ticketData, lang }) => {
  const {
    pnr,
    trainName,
    trainNumber,

    destination,
    boarding,
    jClass,
    quota,

    arrivalDate,
    boardingDate,

    arrivalTime,
    distance,
    passengers,
    QR,
  } = parseBooking(ticketData);

  const getDuration = ({
    departureTime,
    departureDate,
    arrivalTime,
    arrivalDate,
  }) => {
    const start = departureTime + ", " + departureDate;
    const end = arrivalTime + ", " + arrivalDate;

    const date1 = moment(start, "hh:mm a, YYYYMMDD");
    const date2 = moment(end, "hh:mm a, YYYYMMDD");

    const diff = date2.diff(date1);

    let duration = moment.duration(diff);

    var hours = parseInt(duration.asHours().toString());

    var minutes = parseInt(duration.asMinutes().toString());

    minutes = minutes - hours * 60;
    return { hours, minutes };
  };

  const [durationString, setDurationString] = useState("");
  useEffect(() => {
    let duration = getDuration({
      departureDate: moment(boardingDate).format("YYYYMMDD"),
      arrivalDate: moment(arrivalDate).format("YYYYMMDD"),
      departureTime: moment(boardingDate).format("hh:mm a"),
      arrivalTime: moment(arrivalTime, "hh:mm").format("hh:mm a"),
    });
    setDurationString(duration.hours + "hrs " + duration.minutes + "min");
  }, []);

  return (
    <div
      className={`${
        isForCard ? "boarding-pass-container" : "boarding-pass-container2"
      }`}
    >
      <div
        className={`${isForCard ? "boarding-pass" : "boarding-pass2"}`}
        id="boarding-pass"
      >
        {isForCard ? (
          <div className="header">
            <img src={railway} style={{ width: "70px" }} alt="" />
            <img src={disha} style={{ width: "96px" }} alt="" />
            <img src={irctc} style={{ width: "44px" }} alt="" />
          </div>
        ) : (
          <div className="header">
            <img src={railway} style={{ width: "3.5cm" }} alt="" />
            <img src={disha} style={{ width: "5cm" }} alt="" />
            <img src={irctc} style={{ width: "2cm" }} alt="" />
          </div>
        )}

        <div className="hr"></div>
        <div className="first">
          <div className="left">
            <span>{langText[lang].pnr}</span>
            <h4>{pnr}</h4>
            {/* */}
          </div>
          <div className="right">
            <h4>{moment(boardingDate).format("DD")}</h4>
            <div>
              <p>{moment(boardingDate).format("MMM")}</p>
              <span>{moment(boardingDate).format("YYYY")}</span>
            </div>
          </div>
        </div>
        <div className="journey-details">
          <div className="left">
            <span>{langText[lang].class}</span>
            <p>
              {jClass} - <span>{getClassName(jClass)}</span>
            </p>
          </div>
          <div className="right">
            <span>{langText[lang].quota}</span>
            <p>
              {quota} - <span>{getQuotaName(quota)}</span>
            </p>
          </div>
        </div>
        <div className="hr"></div>

        <div className="second">
          <div className="left">
            <span>{langText[lang].from}</span>
            <h4>{boarding}</h4>
            <p>{getStationName(boarding)}</p>
          </div>
          {isForCard ? (
            <img src={arrow} alt="" />
          ) : (
            <img src={arrow} alt="" style={{ width: "1.5cm" }} />
          )}

          <div className="right">
            <span>{langText[lang].to}</span>
            <h4>{destination}</h4>
            <p>{getStationName(destination)}</p>
          </div>
        </div>
        <div className="hr"></div>
        <div className="third">
          <div>
            <h5>{langText[lang].train}</h5>
            <p>{trainName}</p>
            <span>{trainNumber}</span>
          </div>

          <div>
            <h5>{langText[lang].boarding}</h5>
            <p>{getStationName(boarding)}</p>
            <span>{boarding}</span>
          </div>
        </div>
        <div className="hr"></div>
        <div className="fourth">
          <div className="dist">
            <div>
              <span>{langText[lang].duration}</span>
              <p>{durationString}</p>
            </div>
            <div>
              <span style={{ marginTop: "5px" }}>
                {langText[lang].distance}
              </span>
              <p>{distance} km</p>
            </div>
          </div>
          <div className="departure">
            <span>{langText[lang].depart}</span>
            <h5>{moment(boardingDate).format("DD MMM, YYYY")}</h5>
            <p>{moment(boardingDate).format("hh:mm a")}</p>
          </div>
          <div className="departure">
            <span>{langText[lang].arrive}</span>
            <h5>{moment(arrivalDate).format("DD MMM, YYYY")}</h5>
            <p>{moment(arrivalTime, "hh:mm").format("hh:mm a")}</p>
          </div>
        </div>

        <div className="hr"></div>

        <div className="passengers">
          <h4>
            {langText[lang].passengers} ({passengers.length})
          </h4>
          {passengers.map((passenger, index) => {
            return (
              <p>
                {index + 1}. {passenger.name} | {passenger.age} |{" "}
                {passenger.gender} | {passenger.status.status}/
                {passenger.status.coach}/{passenger.status.seat}/
                {passenger.status.berth}
              </p>
            );
          })}
        </div>

        <img
          className="QR"
          src={QR}
          alt=""
          style={isForCard ? {} : { width: 130 }}
        />
        <div className="corover">
          Powered by{" "}
          <img
            src={corover}
            alt=""
            style={
              isForCard
                ? { width: "42px", marginLeft: "4px" }
                : { width: "2cm", marginLeft: "8px" }
            }
          />{" "}
        </div>
      </div>

      {false && (
        <button
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "85%",
            background: "#326abb",
            color: "white",
            border: "none",
            margin: "0 auto",
            borderRadius: "10px",
            padding: "8px",
            cursor: "pointer",
            marginBottom: "10px",
          }}
          // onClick={handleDownload}
        >
          {false ? (
            <Spinner
              borderWidth={"2px"}
              activeColor={"#6495ed"}
              inactiveColor={"#f9f9f9"}
              speed={"1s"}
              size={"18px"}
            />
          ) : (
            <img style={{ width: "18px" }} src={download} alt="" />
          )}

          <p style={{ marginLeft: "8px" }}>
            {lang === "en" ? "Download" : "डाउनलोड"}
          </p>
        </button>
      )}

      {/* <div className="buttons">
      <div onClick={handleDownload}>
        <div className="icon">
          {!isLoadingTicket ? (
            <img src={download} alt="" />
          ) : (
            <Spin indicator={antIcon} />
          )}
        </div>
        <p>{lang === "en" ? "Download" : "डाउनलोड"}</p>
      </div>

      <div onClick={handlePrint}>
        <div className="icon">
          {!isLoadingPrint ? (
            <img src={print} alt="" />
          ) : (
            <Spin indicator={antIcon} />
          )}
        </div>
        <p>{lang === "en" ? "Print" : "प्रिंट"}</p>
      </div>

      <div
        onClick={handleShare}
        style={{ display: display ? "flex" : "none" }}
      >
        <div className="icon">
          {!isLoadingShare ? (
            <img src={share} alt="" />
          ) : (
            <Spin indicator={antIcon} />
          )}
        </div>
        <p>{lang === "en" ? "Share" : "शेयर"}</p>
      </div>

    </div> */}
    </div>
  );
};

export default Eticket;
